import React, { Suspense } from 'react';
import Router from './routes';
import Loader from './modules/shared/components/Loader';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import './pattern.css';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="h-[100vh] flex flex-col">
      <Suspense
        fallback={
          <div
            className="h-screen flex flex-wrap justify-center content-center"
            style={{ background: 'rgba(255,255,255,0.8)' }}
          >
            <Loader />
          </div>
        }
      >
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </Suspense>
    </div>
  );
}

export default App;
