import React from "react";
import "./Loader.css";
import PropTypes from "prop-types";
import Logo from "../../icons/Logo";

const Loader = (props) => {
  return (
    <div
      className={
        props?.forProcess
          ? "ProcessLoader"
          : "w-[100px] animate-loader mx-auto"
      }
    >
      {props?.forProcess ? (
        <img
          src="./assets/loader.svg"
          className="w-[100px] loader-animate"
          alt=""
        ></img>
      ) : (
        <Logo />
      )}
    </div>
  );
};

Loader.propTypes = {
  forProcess: PropTypes.bool,
};

export default Loader;
