import Cookies from "universal-cookie";
const cookies = new Cookies();

/**
 * Checks if user is authenticated
 */

const isAuthorized = (roles) => {
  const user = getLoggedInUser();
  let superRole = user?.role === "super_admin";
  if (
    isAuthenticated() &&
    user &&
    (superRole || roles.some((role) => role.includes(user.role)))
  ) {
    return true;
  } else {
    return false;
  }
};

const isAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    deleteCookies();
    return false;
  }

  const tokenExp = cookies.get("tokenExp");
  if (!tokenExp) {
    localStorage.clear();
    deleteCookies();
    return false;
  } else {
    return true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = cookies.get("email");
  const role = cookies.get("role");
  const tokenExp = cookies.get("tokenExp");

  let userData = { user, role, tokenExp };

  return user && role && tokenExp ? userData : null;
};

const deleteCookies = () => {
  cookies.remove("email", { path: "/" });
  cookies.remove("role", { path: "/" });
  cookies.remove("token", { path: "/" });
  cookies.remove("refreshToken", { path: "/" });
  cookies.remove("tokenExp", { path: "/" });
};

export { isAuthenticated, getLoggedInUser, isAuthorized, deleteCookies };
