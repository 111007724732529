import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isAuthenticated, isAuthorized } from '../modules/shared/helpers/auth';

const Landing = React.lazy(() => import('../modules/pages/Landing'));
const AnminLanding = React.lazy(() => import('../modules/pages/Admin'));
const Transactions = React.lazy(() => import('../modules/pages/Transactions'));
const Login = React.lazy(() => import('../modules/pages/Auth/Login'));
const PasswordPage = React.lazy(() => import('../modules/pages/Auth/Password'));
const Registration = React.lazy(() =>
  import('../modules/pages/Auth/Registration')
);
const EmailVerify = React.lazy(() => import('../modules/pages/Auth/Verify'));
const InvitationCard = React.lazy(() =>
  import('../modules/pages/InvitationCard')
);
const LanInvitationCard = React.lazy(() =>
  import('../modules/pages/LanInvitationCard')
);
const StudentProfile = React.lazy(() =>
  import('../modules/pages/StudentProfile')
);

const Students = React.lazy(() => import('../modules/pages/Students'));
const StudentCards = React.lazy(() => import('../modules/pages/StudentCards'));


const Payment = React.lazy(() => import('../modules/pages/Payment'));
const Onboarding = React.lazy(() => import('../modules/pages/Onboarding'));

const PrivateRoute = ({ roles, children }) => {
  const authenticated = isAuthenticated();
  const authorized = isAuthorized(roles);
  return authenticated ? (
    authorized ? (
      children
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

//-- Does Not Require Auth --//
const landing = {
  path: '/',
  name: 'landing component',
  component: <Landing />,
  // roles: ["Admin", "User"],
  route: Route
};

const adminLanding = {
  path: '/admin',
  name: 'admin home page component',
  component: (
    <PrivateRoute roles={['admin']}>
      <AnminLanding />
    </PrivateRoute>
  ),
  roles: ['Admin'],
  route: Route
};

const transactions = {
  path: '/transactions',
  name: 'transactions page component',
  component: (
    <PrivateRoute roles={['admin']}>
      <Transactions />
    </PrivateRoute>
  ),
  roles: ['Admin'],
  route: Route
};

const login = {
  path: '/login',
  name: 'login component',
  component: <Login />,
  // roles: ["Admin", "User"],
  route: Route
};

const registration = {
  path: '/register',
  name: 'registration component',
  component: <Registration />,
  // roles: ["Admin", "User"],
  route: Route
};

const password = {
  path: '/password/:passwordAction',
  name: 'Password Page component',
  component: <PasswordPage />,
  roles: ['Admin', 'User'],
  route: Route
};

const verify = {
  path: '/verify',
  name: 'verify component',
  component: <EmailVerify />,
  // roles: ["Admin", "User"],
  route: Route
};

const studentProfile = {
  path: '/studentProfile',
  name: 'student profile component',
  component: (
    <PrivateRoute roles={['admin', 'student']}>
      <StudentProfile />
    </PrivateRoute>
  ),
  // roles: ["Admin", "User"],
  route: Route
};

const students = {
  path: '/studentsRegistered',
  name: 'student profile component',
  component: (
    <PrivateRoute roles={['admin']}>
      <Students />
    </PrivateRoute>
  ),
  // roles: ["Admin", "User"],
  route: Route
};

const studentCards = {
  path: '/studentCards',
  name: 'student cards component',
  component: (
    <PrivateRoute roles={['admin']}>
      <StudentCards />
    </PrivateRoute>
  ),
  // roles: ["Admin", "User"],
  route: Route
};

const onboarding = {
  path: '/onboarding',
  name: 'Onboarding component',
  component: (
    <PrivateRoute roles={['admin', 'student']}>
      <Onboarding />
    </PrivateRoute>
  ),
  roles: ['Admin', 'User'],
  route: Route
};

const payment = {
  path: '/payment/:partial?',
  name: 'Payment component',
  component: (
    <PrivateRoute roles={['admin', 'student']}>
      <Payment />
    </PrivateRoute>
  ),
  roles: ['Admin', 'User'],
  route: Route
};

const invitationCard = {
  path: '/invitationCard',
  name: 'Invitation Card component',
  component: (
    <PrivateRoute roles={['admin', 'student']}>
      <InvitationCard />
    </PrivateRoute>
  ),
  roles: ['Admin', 'User'],
  route: Route
};
const lanInvitationCard = {
  path: '/invitationCard/new',
  name: 'New Invitation Card component',
  component: (
    <PrivateRoute roles={['admin', 'student']}>
      <LanInvitationCard />
    </PrivateRoute>
  ),
  // roles: ["Admin", "User"],
  route: Route
};

//-- Require Auth --//

const allRoutes = [
  landing,
  adminLanding,
  login,
  registration,
  verify,
  studentProfile,
  invitationCard,
  onboarding,
  payment,
  password,
  lanInvitationCard,
  transactions,
  students,
  studentCards
];

export { allRoutes };
